<template>
	<!-- Coming soon page-->
	<div class="misc-wrapper">
		<!-- <b-link class="brand-logo">
      <vuexy-logo/>
      <h2 class="brand-text text-primary ml-1">高性能智能建筑系统</h2>
    </b-link> -->

		<div class="misc-inner p-2 p-sm-3">
			<div class="w-100 text-center">
				<h2 class="mb-1">功能开发中 🚀</h2>
				<p class="mb-3">我们正在全力开发，敬请期待</p>

				<!-- <b-button
          :to="{ path: '/login' }"
          class="my-2 btn-sm-block"
          variant="primary"
        >返回首页
        </b-button> -->
			</div>
			<b-img :src="imgUrl" alt="Coming soon page" fluid />
		</div>
	</div>
	<!-- / Coming soon page-->
</template>

<script>
	/* eslint-disable global-require */
	import VuexyLogo from "@/@core/layouts/components/Logo.vue";
	import store from "@/store";

	export default {
		components: {
			VuexyLogo,
		},
		data() {
			return {
				downImg: require("@/assets/images/pages/coming-soon.svg"),
			};
		},
		computed: {
			imgUrl() {
				if (store.state.appConfig.layout.skin === "dark") {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
					return this.downImg;
				}
				return this.downImg;
			},
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/pages/page-misc.scss";
</style>
